<template>
  <external-page-layout>
    <template>
      <div class="has-text-centered mt-2">
        <div class="is-inline-flex has-logo is-clipped box">
          <figure class="image is-centered is-200x200">
            <img :src="eventLogo" v-if="hasEvent && eventLogo" />
            <meeting-application-logo class="svg-icon" v-else></meeting-application-logo>
          </figure>
        </div>
        <div class="title mt-2">{{ $t("email_confirmation.success_title") }}</div>
        <div class="subtitle mt-1" v-if="!lpEnabled">{{ $t("email_confirmation.success_description_event_without_landing_page") }}</div>
        <div class="subtitle mt-1" v-else-if="hasEvent">{{ $t("email_confirmation.success_description_event") }}</div>
        <div class="subtitle mt-1" v-else>{{ $t("email_confirmation.success_description") }}</div>
<!--        <button class="button is-primary mt-3" @click="navigateToMobileApp" v-if="hasEvent">-->
<!--          {{ $t("email_confirmation.navigate_button_app") }}-->
<!--        </button>-->
        <button class="button is-primary mt-3" @click="navigateToIntroduction" v-if="hasEvent && lpEnabled">
          {{ $t("email_confirmation.navigate_button") }}
        </button>

      </div>
    </template>
  </external-page-layout>
</template>

<script>
import ExternalPageLayout from "@/web/components/external/ExternalPageLayout";
import MeetingApplicationLogo from "@/assets/meetingapplication/ma_logo_square.svg";
import Constants from "@/web/constants";
import { mapState } from "vuex";

export default {
  name: "EmailConfirmationSuccess",
  components: { ExternalPageLayout, MeetingApplicationLogo },

  computed: {
    ...mapState(["event", "appId"]),

    hasEvent() {
      return !this.$route.path.startsWith("/registration");
    },

    eventLogo() {
      return this.event && this.event.logo && this.event.logo.file_thumbnail_750_url;
    },

    lpEnabled() {
      if (this.hasEvent) {
        return this.event && !this.isEugEvent && !this.isKukaEvent;
      } else {
        return false;
      }
    },

    isEugEvent() {
      return this.event && this.event.id === 1138;
    },

    isKukaEvent() {
      return this.event && this.event.id === 1233;
    },
  },

  methods: {
    navigateToIntroduction() {
      this.$router.push({ name: Constants.ROUTE_MAIN_INTRODUCTION });
    },

    navigateToMobileApp() {
      let appId = this.appId || 1;
      window.open(`https://meetingapplication.com/app/${appId}/event/${this.event.id}`, "_blank");
    }
  },
};
</script>

<style scoped></style>
